import { Injectable } from "@angular/core";
import { RequestParameters, APIRequestsService } from "@app/core/services/api.requests.service";
import { DataSetEntry } from "@app/modules/statistics/services/utils";
import * as moment from "moment";
import { BehaviorSubject, forkJoin } from "rxjs";
import { switchMap } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class SalesStatisticsPreviewCardService {
    fetch = new BehaviorSubject<boolean>(true);
    loadingRevenueData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    errorRevenueData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    revenueLabels: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    revenueDatasets: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    revenueData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    revenueLast7Days: BehaviorSubject<any> = new BehaviorSubject<any>(0);
    revenueDelta: BehaviorSubject<any> = new BehaviorSubject<any>(0);

    constructor(private requestsService: APIRequestsService) {}

    init() {
        this.fetch
            .pipe(
                switchMap((x) => {
                    return this.getRevenueData();
                })
            )
            .subscribe({
                next: (data: any) => {
                    this.revenueLast7Days.next(data?.totals?.volumeInUsd);
                    this.revenueData.next(data);
                    const labels = data["labels"];
                    const datasets = data.datasets.map((_element: any, _index: number) => {
                        const label = _element.label;
                        const values = [];
                        _element.data.forEach((dataItem) => {
                            values.push(dataItem.volumeInUsd);
                        });
                        return new DataSetEntry("Revenue", values);
                    });

                    this.revenueLabels.next(labels);
                    this.revenueDatasets.next(datasets);
                    this.loadingRevenueData.next(false);
                    this.errorRevenueData.next(false);
                },
                error: (_error: any) => {
                    this.revenueLabels.next([]);
                    this.revenueDatasets.next([]);
                    this.loadingRevenueData.next(false);
                    this.errorRevenueData.next(true);
                },
            });
    }

    getRevenueData() {
        this.loadingRevenueData.next(true);
        const now = new Date();
        let parameters: RequestParameters[] = [
            {
                label: "from",
                data: moment(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 8)).format("yyyy-MM-DD"),
            },
            {
                label: "to",
                data: moment(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1)).format("yyyy-MM-DD"),
            },
        ];

        return this.requestsService.get("/api/statistics/chart", [...parameters, { label: "type", data: "revenue" }]);
    }
}
