<div class="content-d">
    <img class="close-icon" (click)="closeDialog()" src="/assets/images/closeX.svg" />
    <img class="warning-icon" src="assets/images/warning_pink_round.svg" />
    <div translate class="title">leavingSubmissionConfirmationDialog.title</div>
    <div class="bottom-margin">
        <p class="text" translate>leavingSubmissionConfirmationDialog.text</p>
        <p class="disclaimer" translate>leavingSubmissionConfirmationDialog.disclaimer</p>
    </div>
    <div class="buttons">
        <app-button
            class="button"
            (onClick)="leave()"
            [showButtonSpinner]="submissionService.savingDraft | async"
            [small]="true"
            label="{{ 'leavingSubmissionConfirmationDialog.saveDraft' | translate }}"
        ></app-button>
        <app-button class="button" (onClick)="cancel()" [small]="true" [pinkOnWhite]="true" label="{{ 'leavingSubmissionConfirmationDialog.cancel' | translate }}"></app-button>
    </div>
</div>
