import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { IndicativeService } from "@app/core/services/indicative.service";
import { UserService } from "@app/core/services/user.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "@env/environment";

@Injectable({
    providedIn: "root",
})
export class DistributionPolicyMorService {
    companyLocation = new BehaviorSubject<string>(null);
    invalidCompanyLocation = new BehaviorSubject<boolean>(false);

    companyOnwardTransferIntention = new BehaviorSubject<string>(null);
    invalidCompanyOnwardTransferIntention = new BehaviorSubject<boolean>(false);

    companyTransferredDataStorageLocation = new BehaviorSubject<string>(null);
    invalidCompanyTransferredDataStorageLocation = new BehaviorSubject<boolean>(false);

    contactPersonEmailAddress = new BehaviorSubject<string>(null);
    invalidContactPersonEmailAddress = new BehaviorSubject<boolean>(false);

    contactPersonName = new BehaviorSubject<string>(null);
    invalidContactPersonName = new BehaviorSubject<boolean>(false);

    dataSecurityMeasures = new BehaviorSubject<string>(null);
    invalidDataSecurityMeasures = new BehaviorSubject<boolean>(false);

    hasCompanyReceivedDiscloseRequests = new BehaviorSubject<string>(null);
    invalidHasCompanyReceivedDiscloseRequests = new BehaviorSubject<boolean>(null);

    isCompanySubjectToLocalLaws = new BehaviorSubject<string>(null);
    invalidIsCompanySubjectToLocalLaws = new BehaviorSubject<boolean>(null);

    personalDataSafeguardMeasureDetails = new BehaviorSubject<string>(null);
    invalidPersonalDataSafeguardMeasureDetails = new BehaviorSubject<boolean>(null);

    transmissionChannel = new BehaviorSubject<string>(null);
    invalidTransmissionChannel = new BehaviorSubject<boolean>(null);

    isValid = new BehaviorSubject<boolean>(true);
    savingData = new BehaviorSubject<boolean>(false);
    errorOccured = new BehaviorSubject<boolean>(false);
    dataHasBeenSaved = new BehaviorSubject<boolean>(true);

    constructor(private indicativeService: IndicativeService, private http: HttpClient, private userService: UserService) {}

    reset() {
        this.errorOccured.next(false);

        this.companyLocation.next(null);
        this.invalidCompanyLocation.next(null);

        this.companyOnwardTransferIntention.next(null);
        this.invalidCompanyOnwardTransferIntention.next(null);

        this.companyTransferredDataStorageLocation.next(null);
        this.invalidCompanyTransferredDataStorageLocation.next(null);

        this.contactPersonEmailAddress.next(null);
        this.invalidContactPersonEmailAddress.next(null);

        this.contactPersonName.next(null);
        this.invalidContactPersonName.next(null);

        this.dataSecurityMeasures.next(null);
        this.invalidDataSecurityMeasures.next(null);

        this.hasCompanyReceivedDiscloseRequests.next(null);
        this.invalidHasCompanyReceivedDiscloseRequests.next(null);

        this.isCompanySubjectToLocalLaws.next(null);
        this.invalidIsCompanySubjectToLocalLaws.next(null);

        this.personalDataSafeguardMeasureDetails.next(null);
        this.invalidPersonalDataSafeguardMeasureDetails.next(null);

        this.transmissionChannel.next(null);
        this.invalidTransmissionChannel.next(null);
    }

    onUserInteraction() {
        this.dataHasBeenSaved.next(false);
        this.errorOccured.next(false);
        this.indicativeService.sendEvent("app_submission_form_interacting");
        this.indicativeService.sendEvent("app_submission_form_distribution_policy_interacting");
    }

    validateCompanyLocation() {
        this.invalidCompanyLocation.next(!this.companyLocation.value);
    }

    validateCompanyOnwardTransferIntention() {
        this.invalidCompanyOnwardTransferIntention.next(!this.companyOnwardTransferIntention.value);
    }

    validateCompanyTransferredDataStorageLocation() {
        this.invalidCompanyTransferredDataStorageLocation.next(!this.companyTransferredDataStorageLocation.value);
    }

    validateContactPersonEmailAddress() {
        this.invalidContactPersonEmailAddress.next(!this.contactPersonEmailAddress.value);
    }

    validateContactPersonName() {
        this.invalidContactPersonName.next(!this.contactPersonName.value);
    }

    validateDataSecurityMeasures() {
        this.invalidDataSecurityMeasures.next(!this.dataSecurityMeasures.value);
    }

    validateHasCompanyReceivedDiscloseRequests() {
        this.invalidHasCompanyReceivedDiscloseRequests.next(!this.hasCompanyReceivedDiscloseRequests.value);
    }

    validateIsCompanySubjectToLocalLaws() {
        this.invalidIsCompanySubjectToLocalLaws.next(!this.isCompanySubjectToLocalLaws.value);
    }

    validatePersonalDataSafeguardMeasureDetails() {
        this.invalidPersonalDataSafeguardMeasureDetails.next(!this.personalDataSafeguardMeasureDetails.value);
    }

    validateTransmissionChannel() {
        this.invalidTransmissionChannel.next(!this.transmissionChannel.value);
    }

    validateFields() {
        this.validateCompanyLocation();
        this.validateCompanyOnwardTransferIntention();
        this.validateCompanyTransferredDataStorageLocation();
        this.validateContactPersonEmailAddress();
        this.validateContactPersonName();
        this.validateDataSecurityMeasures();
        this.validateHasCompanyReceivedDiscloseRequests();
        this.validateIsCompanySubjectToLocalLaws();
        this.validatePersonalDataSafeguardMeasureDetails();
        this.validateTransmissionChannel();
    }

    validateForm() {
        let isValid =
            !!this.companyLocation.value &&
            !!this.companyOnwardTransferIntention.value &&
            !!this.companyTransferredDataStorageLocation.value &&
            !!this.contactPersonEmailAddress.value &&
            !!this.contactPersonName.value &&
            !!this.dataSecurityMeasures.value &&
            !!this.hasCompanyReceivedDiscloseRequests.value &&
            !!this.isCompanySubjectToLocalLaws.value &&
            !!this.personalDataSafeguardMeasureDetails.value &&
            !!this.transmissionChannel.value;

        this.isValid.next(isValid);
    }

    getFormParameters(): any {
        const parameters: any = {};

        if (this.companyLocation.value) {
            parameters["developer.distributionPolicy.companyLocation"] = this.companyLocation.value;
        }
        if (this.companyOnwardTransferIntention.value) {
            parameters["developer.distributionPolicy.companyOnwardTransferIntention"] = this.companyOnwardTransferIntention.value;
        }
        if (this.companyTransferredDataStorageLocation.value) {
            parameters["developer.distributionPolicy.companyTransferredDataStorageLocation"] = this.companyTransferredDataStorageLocation.value;
        }
        if (this.contactPersonEmailAddress.value) {
            parameters["developer.distributionPolicy.contactPersonEmailAddress"] = this.contactPersonEmailAddress.value;
        }
        if (this.contactPersonName.value) {
            parameters["developer.distributionPolicy.contactPersonName"] = this.contactPersonName.value;
        }
        if (this.dataSecurityMeasures.value) {
            parameters["developer.distributionPolicy.dataSecurityMeasures"] = this.dataSecurityMeasures.value;
        }
        if (this.hasCompanyReceivedDiscloseRequests.value) {
            parameters["developer.distributionPolicy.hasCompanyReceivedDiscloseRequests"] = this.hasCompanyReceivedDiscloseRequests.value;
        }
        if (this.isCompanySubjectToLocalLaws.value) {
            parameters["developer.distributionPolicy.isCompanySubjectToLocalLaws"] = this.isCompanySubjectToLocalLaws.value;
        }
        if (this.personalDataSafeguardMeasureDetails.value) {
            parameters["developer.distributionPolicy.personalDataSafeguardMeasureDetails"] = this.personalDataSafeguardMeasureDetails.value;
        }
        if (this.transmissionChannel.value) {
            parameters["developer.distributionPolicy.transmissionChannel"] = this.transmissionChannel.value;
        }

        return parameters;
    }

    saveData() {
        this.savingData.next(true);
        this.errorOccured.next(false);
        this.dataHasBeenSaved.next(false);
        this.validateForm();

        if (this.isValid.value) {
            const parameters: any = this.getFormParameters();

            const url = `${environment.baseNodeUrlServices}/api/accounts/${this.userService.loggedAccount.value.uid}`;
            this.http.patch(url, parameters).subscribe({
                next: (res) => {
                    this.dataHasBeenSaved.next(true);
                    this.savingData.next(false);
                    this.errorOccured.next(false);
                },
                error: () => {
                    this.dataHasBeenSaved.next(false);
                    this.savingData.next(false);
                    this.errorOccured.next(true);
                },
            });
        } else {
            this.validateFields();
            this.savingData.next(false);
            this.errorOccured.next(false);
        }
    }
}
