import { Injectable } from "@angular/core";
import { APIRequestsService } from "@app/core/services/api.requests.service";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class ApplicationsListCardService {
    hasAppsWithBilling: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    loadingAppsList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    errorAppsList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    appsList: BehaviorSubject<any> = new BehaviorSubject<any>([]);

    constructor(private requestsService: APIRequestsService) {}

    getAllPackagesList() {
        this.loadingAppsList.next(true);

        const url = "/api/dashboard/developers/applicationsList";

        this.requestsService.get(url).subscribe({
            next: (res: any) => {
                if (!res?.length) {
                    this.loadingAppsList.next(false);
                }
                res.forEach((element, index) => {
                    if (element.type === "withLiveVersions") {
                        if (element?.accountApplication?.latestVersion?.billingMethod && element?.accountApplication?.latestVersion?.billingMethod !== "none") {
                            this.hasAppsWithBilling.next(true);
                        }
                        element.feStatus = "distributed";
                    }
                    if (element.type === "withApprovedVersions") {
                        if (element?.accountApplication?.latestVersion?.billingMethod && element?.accountApplication?.latestVersion?.billingMethod !== "none") {
                            this.hasAppsWithBilling.next(true);
                        }
                        element.feStatus = "undistributed";
                    }
                    if (element.type === "pending") {
                        if (element?.accountApplication?.latestVersion?.billingMethod && element?.accountApplication?.latestVersion?.billingMethod !== "none") {
                            this.hasAppsWithBilling.next(true);
                        }
                        element.feStatus = "pending";
                    }
                    if (element.type === "draft") {
                        element.feStatus = "draft";
                    }
                });

                this.appsList.next(res);
                this.loadingAppsList.next(false);
            },
            error: (_error: any) => {
                this.loadingAppsList.next(false);
                this.errorAppsList.next(true);
            },
        });
    }
}
