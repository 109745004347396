import { HttpClient, HttpEvent, HttpEventType } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { AccountsService } from "@app/core/services/accounts.service";
import { IndicativeService } from "@app/core/services/indicative.service";
import { UserService } from "@app/core/services/user.service";
import { ImageDataForValidation, UtilsService } from "@app/core/services/utils.service";
import { ItsOnOurSideComponentModal } from "@app/shared/its-on-our-side-modal/its-on-our-side-modal.component";
import { PaymentNeededComponent } from "@app/shared/payment-needed/payment-needed.component";
import { SelectEntry } from "@app/shared/select/select.component";
import { environment } from "@env/environment";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { BehaviorSubject, catchError, scan } from "rxjs";
import { DistributionPolicyMorService } from "../../../shared/distribution-policy-mor/distribution-policy-mor.service";
import { AppsListService } from "../apps-list/apps-list.service";
import { AppInformationService } from "./app-information/app-information.service";
import { BillingService } from "./billing/billing.service";
import { DistributionChannelsService } from "./distribution-channels/distribution-channels.service";
import { DistributionCountriesSubmissionService } from "./distribution-countries-submission/distribution-countries-submission.service";
import { FilesUploadService } from "./files-upload/files-upload.service";
import { LanguagesMediaService } from "./languages-media/languages-media.service";
import { MetadataViewService } from "./metadata-view/metadata-view.service";
import { OwnershipRequestService } from "./ownership-request/ownership-request.service";
import { ReleaseScheduleComponent } from "./release-schedule/release-schedule.component";
import { NotificationsService } from "@app/core/services/notifications.service";

export interface UploadProgress {
    progress: number;
    state: "PENDING" | "IN_PROGRESS" | "DONE";
}
export interface LanguageMediaI {
    type: string;
    fileMimeType: string;
    height: number;
    width: number;
    size: number;
}
export interface FormCard {
    index: number;
    name: string;
    extra: string;
    closed: boolean;
    done: boolean;
    actionNeeded: boolean;
}
@Injectable({
    providedIn: "root",
})
export class SubmissionService {
    savingDraft = new BehaviorSubject<boolean>(false);
    isMandatory = new BehaviorSubject<boolean>(false);
    submitRetries = new BehaviorSubject<number>(0);
    addingPackage = new BehaviorSubject<boolean>(true);
    submitting = new BehaviorSubject<boolean>(false);
    uploadingFiles: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    uploadProgress: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    processingFiles: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    submitErrors: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    packageName: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    operatingSystem: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    message: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    formCards: BehaviorSubject<FormCard[]> = new BehaviorSubject<FormCard[]>([]);
    everyCardIsDone: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);
    anyDoneHasBeenClicked: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);
    scheduleReleaseDate = new BehaviorSubject<string>(null);
    selectedOption = new BehaviorSubject<string>("now");
    scheduleReleaseTime = new BehaviorSubject<SelectEntry>(null);
    showDraftOptions: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);

    constructor(
        private http: HttpClient,
        public ownershipRequestService: OwnershipRequestService,
        public billingService: BillingService,
        public appInformationService: AppInformationService,
        public languageMediaService: LanguagesMediaService,
        public filesUploadService: FilesUploadService,
        public distributionCountriesSubmissionService: DistributionCountriesSubmissionService,
        public distributionChannelsService: DistributionChannelsService,
        public distributionPolicyMorService: DistributionPolicyMorService,
        public metadataViewService: MetadataViewService,
        private router: Router,
        private accountsService: AccountsService,
        private translate: TranslateService,
        private dialog: MatDialog,
        private userService: UserService,
        private utilsService: UtilsService,
        private domSanitizer: DomSanitizer,
        private appsListService: AppsListService,
        private indicativeService: IndicativeService,
        private notificationsService: NotificationsService
    ) {
        this.packageName.subscribe({
            next: (packageName: any) => {
                if (packageName) {
                    this.resetForm();
                    this.getSubmissionForm().subscribe({
                        next: (response: any[]) => {
                            this.buildSubmissionForm(response);
                            this.addingPackage.next(false);
                        },
                    });
                }
            },
        });

        this.formCards.subscribe({
            next: (formCards: FormCard[]) => {
                this.everyCardIsDone.next(this.isEveryFormCardDone(formCards));
            },
        });
    }

    isEveryFormCardDone(formCards: FormCard[]) {
        if (!formCards) return false;
        if (!formCards.length) return false;
        return formCards.every((el) => el.done);
    }

    addPackage(packageName: string) {
        if (this.packageName.value !== packageName) {
            this.addingPackage.next(true);

            const parameters: any = (() => {
                return {
                    name: packageName,
                    addDownloadRequest: "true",
                };
            })();
            this.http.post(`${environment.baseNodeUrlServices}/api/applications?operatingSystem=${this.operatingSystem.value}`, parameters).subscribe({
                next: () => {
                    this.packageName.next(packageName);
                },
            });
        }
    }

    deleteDraft() {
        const url = environment.baseNodeUrlServices + `/api/applications/${this.packageName.value}/submissionDraft`;

        return this.http.delete(url);
    }

    saveAsDraft() {
        this.savingDraft.next(true);

        const formData = new FormData();

        if (this.formCards.value.some((card) => card.name === "distribution-countries")) {
            const distributionCountriesFormData: FormData = this.distributionCountriesSubmissionService.getFormData();
            distributionCountriesFormData.forEach((value: FormDataEntryValue, key: string) => {
                formData.append(key, value);
            });
        }

        if (this.formCards.value.some((card) => card.name === "distribution-channels")) {
            const distributionChannelsFormData: FormData = this.distributionChannelsService.getFormData();
            distributionChannelsFormData.forEach((value: FormDataEntryValue, key: string) => {
                formData.append(key, value);
            });
        }

        if (this.formCards.value.some((card) => card.name === "app-information")) {
            const appInformationFormData: FormData = this.appInformationService.getFormData();
            appInformationFormData.forEach((value: FormDataEntryValue, key: string) => {
                formData.append(key, value);
            });
        }
        if (this.formCards.value.some((card) => card.name === "languages-media")) {
            const languageMediaFormData: FormData = this.languageMediaService.getFormDataForDraft();
            languageMediaFormData.forEach((value: FormDataEntryValue, key: string) => {
                formData.append(key, value);
            });
        }

        formData.append("name", this.packageName?.value);
        const url = environment.baseUploaderNodeUrlServices + "/api/submissionDraft?operatingSystem=" + this.operatingSystem.value;

        return this.http.post(url, formData);
    }

    onDoneClick(index: number) {
        this.anyDoneHasBeenClicked.next(true);
        let formCards: FormCard[] = this.formCards.value;
        formCards[index].done = true;
        formCards[index].closed = true;
        this.formCards.next(formCards);

        if (formCards[index].name === "ownership-request") {
            this.indicativeService.sendEvent("app_submission_form_interacting");
            this.indicativeService.sendEvent("app_submission_form_ownership_request_interacting");
            this.indicativeService.sendEvent("app_submission_form_ownership_request_done");
        }

        if (formCards[index].name === "billing") {
            this.indicativeService.sendEvent("app_submission_form_interacting");
            this.indicativeService.sendEvent("app_submission_form_billing_interacting");
            this.indicativeService.sendEvent("app_submission_form_billing_done");
        }

        if (formCards[index].name === "files") {
            this.indicativeService.sendEvent("app_submission_form_interacting");
            this.indicativeService.sendEvent("app_submission_form_files_interacting");
            this.indicativeService.sendEvent("app_submission_form_files_done");
        }

        if (formCards[index].name === "distribution-countries") {
            this.indicativeService.sendEvent("app_submission_form_interacting");
            this.indicativeService.sendEvent("app_submission_form_distribution_countries_interacting");
            this.indicativeService.sendEvent("app_submission_form_distribution_countries_done");
        }

        if (formCards[index].name === "distribution-channels") {
            this.indicativeService.sendEvent("app_submission_form_interacting");
            this.indicativeService.sendEvent("app_submission_form_distribution_channels_interacting");
            this.indicativeService.sendEvent("app_submission_form_distribution_channels_done");
        }

        if (formCards[index].name === "distribution-policy-mor") {
            this.indicativeService.sendEvent("app_submission_form_interacting");
            this.indicativeService.sendEvent("app_submission_form_distribution_policy_interacting");
            this.indicativeService.sendEvent("app_submission_form_distribution_policy_done");
        }

        if (formCards[index].name === "app-information") {
            this.indicativeService.sendEvent("app_submission_form_interacting");
            this.indicativeService.sendEvent("app_submission_form_app_information_interacting");
            this.indicativeService.sendEvent("app_submission_form_app_information_done");
        }

        if (formCards[index].name === "languages-media") {
            this.indicativeService.sendEvent("app_submission_form_interacting");
            this.indicativeService.sendEvent("app_submission_form_languages_media_interacting");
            this.indicativeService.sendEvent("app_submission_form_languages_media_done");
        }

        if (formCards[index].name === "message") {
            this.indicativeService.sendEvent("app_submission_form_interacting");
            this.indicativeService.sendEvent("app_submission_form_message_interacting");
            this.indicativeService.sendEvent("app_submission_form_message_done");
        }
    }

    onPanelOpen(index: number) {
        let formCards: FormCard[] = this.formCards.value;
        formCards[index].closed = false;
        this.formCards.next(formCards);
    }

    buildSubmissionForm(backEndCardsList: any[]) {
        let formCards: FormCard[] = [];

        const certificationViaTokenBackEndCard = backEndCardsList.filter((backEndCard) => ["ownership-via-token"].includes(backEndCard.name));
        if (certificationViaTokenBackEndCard && certificationViaTokenBackEndCard.length > 0) {
            formCards.push({
                index: formCards.length,
                name: "ownership-via-token",
                extra: "",
                closed: false,
                done: true,
                actionNeeded: false,
            });
        }

        const ownershipRequestBackEndCard = backEndCardsList.filter((backEndCard) => ["ownership-via-form"].includes(backEndCard.name));
        if (ownershipRequestBackEndCard && ownershipRequestBackEndCard.length > 0) {
            formCards.push({
                index: formCards.length,
                name: "ownership-request",
                extra: ownershipRequestBackEndCard[0].data,
                closed: false,
                done: true,
                actionNeeded: false,
            });
        }

        const billingBackEndCard = backEndCardsList.filter((backEndCard) => ["billing"].includes(backEndCard.name));
        if (billingBackEndCard && billingBackEndCard.length > 0) {
            formCards.push({
                index: formCards.length,
                name: "billing",
                extra: "",
                closed: false,
                done: false,
                actionNeeded: false,
            });

            this.billingService.hasBilling.next(true);
        }

        const billingOptionalBackEndCard = backEndCardsList.filter((backEndCard) => ["billing-optional"].includes(backEndCard.name));

        if (billingOptionalBackEndCard && billingOptionalBackEndCard.length > 0) {
            formCards.push({
                index: formCards.length,
                name: "billing",
                extra: "optional",
                closed: false,
                done: false,
                actionNeeded: false,
            });

            if (billingOptionalBackEndCard[0].data) {
                this.billingService.hasBilling.next(billingOptionalBackEndCard[0].data.defaultValue ? true : false);
                if (this.billingService.hasBilling.value) {
                    const billingMethod = billingOptionalBackEndCard[0].data.defaultMethodValue === "catappult-sdk" ? "sdk" : "osp";
                    this.billingService.billingMethod.next(billingMethod);
                    this.billingService.showOspOption.next(billingMethod === "osp");
                }
            }
        }

        const filesBackEndCard = backEndCardsList.filter((backEndCard) => ["files"].includes(backEndCard.name));
        const psvBackEndCard = backEndCardsList.filter((backEndCard) => ["psv"].includes(backEndCard.name));
        if (filesBackEndCard && filesBackEndCard.length > 0) {
            formCards.push({
                index: formCards.length,
                name: "files",
                extra: psvBackEndCard.length > 0 ? "psv" : "",
                closed: false,
                done: false,
                actionNeeded: true,
            });

            this.filesUploadService.isValid.next(false);
        }

        const distributionCountriesBackEndCard = backEndCardsList.filter((backEndCard) => ["distribution-countries"].includes(backEndCard.name));
        if (distributionCountriesBackEndCard && distributionCountriesBackEndCard.length > 0) {
            this.distributionCountriesSubmissionService.init(distributionCountriesBackEndCard[0].data);

            formCards.push({
                index: formCards.length,
                name: "distribution-countries",
                extra: "",
                closed: false,
                done: true,
                actionNeeded: false,
            });
        }

        const distributionChannelsBackEndCard = backEndCardsList.filter((backEndCard) => ["distribution-channels"].includes(backEndCard.name));
        if (distributionChannelsBackEndCard && distributionChannelsBackEndCard.length > 0) {
            this.distributionChannelsService.init(distributionChannelsBackEndCard[0].data);

            formCards.push({
                index: formCards.length,
                name: "distribution-channels",
                extra: "",
                closed: false,
                done: true,
                actionNeeded: false,
            });
        }

        const metadataEditOptionalBackEndCard = backEndCardsList.filter((backEndCard) => ["metadata-edit-optional"].includes(backEndCard.name));

        if (metadataEditOptionalBackEndCard && metadataEditOptionalBackEndCard.length > 0) {
            this.showDraftOptions.next(true);
            this.populateApplicationData(metadataEditOptionalBackEndCard[0].data);
            this.populateLocales(metadataEditOptionalBackEndCard[0].data.locales);

            this.appInformationService.isValid.next(this.appInformationService.validateForm());

            const isFromDraft: boolean = (() => {
                if (metadataEditOptionalBackEndCard[0].data.isFromDraft) {
                    return true;
                }
                return false;
            })();

            if (this.appInformationService.isValid.value === true) {
                this.appInformationService.displayAppInformation.next(isFromDraft);
            }
            this.languageMediaService.isValid.next(this.languageMediaService.validateForm());
            if (this.languageMediaService.isValid.value === true) {
                this.languageMediaService.displayLanguagesMedia.next(isFromDraft);
            }

            formCards.push({
                index: formCards.length,
                name: "app-information",
                extra: this.appInformationService.isValid.value === true && !isFromDraft ? "optional" : "",
                closed: false,
                done: this.appInformationService.isValid.value,
                actionNeeded: true,
            });

            formCards.push({
                index: formCards.length,
                name: "languages-media",
                extra: this.languageMediaService.isValid.value === true && !isFromDraft ? "optional" : "",
                closed: false,
                done: this.appInformationService.isValid.value,
                actionNeeded: true,
            });
        }

        const metadataEditBackEndCard = backEndCardsList.filter((backEndCard) => ["metadata-edit"].includes(backEndCard.name));

        if (metadataEditBackEndCard && metadataEditBackEndCard.length > 0) {
            this.showDraftOptions.next(true);

            formCards.push({
                index: formCards.length,
                name: "app-information",
                extra: "",
                closed: false,
                done: false,
                actionNeeded: true,
            });

            formCards.push({
                index: formCards.length,
                name: "languages-media",
                extra: "",
                closed: false,
                done: false,
                actionNeeded: true,
            });

            this.appInformationService.isValid.next(false);
            this.languageMediaService.isValid.next(false);
        }

        const metadataViewBackEndCard = backEndCardsList.filter((backEndCard) => ["metadata-view"].includes(backEndCard.name));
        if (metadataViewBackEndCard && metadataViewBackEndCard.length > 0) {
            formCards.push({
                index: formCards.length,
                name: "metadata-view",
                extra: "",
                closed: false,
                done: true,
                actionNeeded: false,
            });

            this.populateMetadataView(metadataViewBackEndCard[0].data);
        }

        const distributionPolicyFormCard = backEndCardsList.filter((backEndCard) => ["distribution-policy-form"].includes(backEndCard.name));
        if (distributionPolicyFormCard && distributionPolicyFormCard.length > 0) {
            this.distributionPolicyMorService.isValid.next(false);
            this.distributionPolicyMorService.dataHasBeenSaved.next(false);

            formCards.push({
                index: formCards.length,
                name: "distribution-policy-mor",
                extra: "",
                closed: false,
                done: false,
                actionNeeded: true,
            });

            this.populateDistributionPolicyMor(distributionPolicyFormCard[0].data);
        }

        const messageBackEndCard = backEndCardsList.filter((backEndCard) => ["message"].includes(backEndCard.name));
        if (messageBackEndCard && messageBackEndCard.length > 0) {
            formCards.push({
                index: formCards.length,
                name: "message",
                extra: "",
                closed: false,
                done: true,
                actionNeeded: false,
            });
        }

        this.formCards.next(formCards);
    }

    populateMetadataView(backEndMetadata) {
        if (backEndMetadata.youtubeUrl) {
            backEndMetadata.safeYoutubeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(backEndMetadata.youtubeUrl.replace("autoplay=1", "autoplay=0"));
        }

        backEndMetadata.ageRatingString = "Unknown";
        if (backEndMetadata.ageRating) {
            if (backEndMetadata.ageRating === 1) {
                backEndMetadata.ageRatingString = "Everyone";
            }
            if (backEndMetadata.ageRating === 5) {
                backEndMetadata.ageRatingString = "Very Low Maturity (tween >= 7)";
            }
            if (backEndMetadata.ageRating === 2) {
                backEndMetadata.ageRatingString = "Low Maturity (pre-teen >= 12)";
            }
            if (backEndMetadata.ageRating === 3) {
                backEndMetadata.ageRatingString = "Medium Maturity (teen >= 16)";
            }
            if (backEndMetadata.ageRating === 4) {
                backEndMetadata.ageRatingString = "High Maturity (mature >= 18)";
            }
        }

        this.metadataViewService.marketInformation.next(backEndMetadata);
    }

    populateDistributionPolicyMor(backEndDistributionPolicyMor) {
        this.distributionPolicyMorService.companyLocation.next(backEndDistributionPolicyMor["companyLocation"]);
        this.distributionPolicyMorService.companyOnwardTransferIntention.next(backEndDistributionPolicyMor["companyOnwardTransferIntention"]);
        this.distributionPolicyMorService.companyTransferredDataStorageLocation.next(backEndDistributionPolicyMor["companyTransferredDataStorageLocation"]);
        this.distributionPolicyMorService.contactPersonEmailAddress.next(backEndDistributionPolicyMor["contactPersonEmailAddress"]);
        this.distributionPolicyMorService.contactPersonName.next(backEndDistributionPolicyMor["contactPersonName"]);
        this.distributionPolicyMorService.dataSecurityMeasures.next(backEndDistributionPolicyMor["dataSecurityMeasures"]);
        this.distributionPolicyMorService.hasCompanyReceivedDiscloseRequests.next(backEndDistributionPolicyMor["hasCompanyReceivedDiscloseRequests"]);
        this.distributionPolicyMorService.isCompanySubjectToLocalLaws.next(backEndDistributionPolicyMor["isCompanySubjectToLocalLaws"]);
        this.distributionPolicyMorService.personalDataSafeguardMeasureDetails.next(backEndDistributionPolicyMor["personalDataSafeguardMeasureDetails"]);
        this.distributionPolicyMorService.transmissionChannel.next(backEndDistributionPolicyMor["transmissionChannel"]);
    }

    populateApplicationData(backEndAppInformation) {
        const categorySelectDataThatMatchesBeResponse = this.utilsService.categoriesAndroidAutoCompleteData.value.find((categorySelectData) => {
            return categorySelectData.category === backEndAppInformation.category && categorySelectData.subCategory === backEndAppInformation.subCategory;
        });

        if (categorySelectDataThatMatchesBeResponse) {
            this.appInformationService.updateCategory(categorySelectDataThatMatchesBeResponse);
        }

        const maturityLevelSelectDataThatMatchesBeResponse = this.utilsService.maturityLevelsAndroidAutoCompleteData.value.find((maturityLevelSelectData) => {
            return String(maturityLevelSelectData.id) === String(backEndAppInformation.ageRating);
        });

        if (maturityLevelSelectDataThatMatchesBeResponse) {
            this.appInformationService.updateAgeRating(maturityLevelSelectDataThatMatchesBeResponse);
        }

        this.appInformationService.updateSupportEmailAddress(backEndAppInformation.supportEmailAddress);
        this.appInformationService.updateWebsite(backEndAppInformation.websiteUrl);
        this.appInformationService.updatePrivacyPolicyUrl(backEndAppInformation.privacyPolicyUrl);
        this.appInformationService.updateYoutubeUrl(backEndAppInformation.youtubeUrl);
        this.appInformationService.icon.next(backEndAppInformation.icon);
        this.appInformationService.featureGraphic.next(backEndAppInformation.featureGraphic);
    }

    populateLocales(backEndLocales) {
        let locales = { en_GB: {} };
        backEndLocales?.forEach((backEndLocale) => {
            locales[backEndLocale.locale.name] = {
                title: backEndLocale.title,
                description: backEndLocale.description,
                releaseNotes: backEndLocale.news,
                screenshots: backEndLocale.screenshots,
            };
        });
        this.languageMediaService.locales.next(locales);
    }

    getSubmissionForm() {
        const url: string = `${environment.baseNodeUrlServices}/api/applications/${this.packageName.value}/submissionForm?operatingSystem=${this.operatingSystem.value}`;

        return this.http.get(url);
    }

    validateImageData(imageData: ImageDataForValidation) {
        return this.utilsService.getValidationForImageData(imageData);
    }

    formIsValid() {
        return (
            !!this.everyCardIsDone.value &&
            !!this.filesUploadService.isValid.value &&
            !!this.appInformationService.isValid.value &&
            !!this.languageMediaService.isValid.value &&
            !!this.distributionPolicyMorService.dataHasBeenSaved.value
        );
    }

    submitApp() {
        this.indicativeService.sendEvent("app_submission_form_attempting");

        if (!this.formIsValid()) {
            return;
        }

        this.submitting.next(true);
        this.uploadingFiles.next(true);
        this.uploadProgress.next(0);
        this.processingFiles.next(false);
        this.submitErrors.next(null);

        const url: string = `${environment.baseUploaderNodeUrlServices}/api?operatingSystem=${this.operatingSystem.value}`;

        const formData = new FormData();

        if (this.formCards.value.some((card) => card.name === "billing")) {
            const billingFormData: FormData = this.billingService.getFormData();
            billingFormData.forEach((value: FormDataEntryValue, key: string) => {
                formData.append(key, value);
            });
        }

        if (this.formCards.value.some((card) => card.name === "files")) {
            const filesUploadFormData: FormData = this.filesUploadService.getFormData();
            filesUploadFormData.forEach((value: FormDataEntryValue, key: string) => {
                formData.append(key, value);
            });
        }

        if (this.formCards.value.some((card) => card.name === "distribution-countries")) {
            const distributionCountriesFormData: FormData = this.distributionCountriesSubmissionService.getFormData();
            distributionCountriesFormData.forEach((value: FormDataEntryValue, key: string) => {
                formData.append(key, value);
            });
        }

        if (this.formCards.value.some((card) => card.name === "distribution-channels")) {
            const distributionChannelsFormData: FormData = this.distributionChannelsService.getFormData();
            distributionChannelsFormData.forEach((value: FormDataEntryValue, key: string) => {
                formData.append(key, value);
            });
        }

        if (this.formCards.value.some((card) => card.name === "app-information")) {
            const appInformationFormData: FormData = this.appInformationService.getFormData();
            appInformationFormData.forEach((value: FormDataEntryValue, key: string) => {
                formData.append(key, value);
            });
        }

        if (this.formCards.value.some((card) => card.name === "languages-media")) {
            const languageMediaFormData: FormData = this.languageMediaService.getFormData();
            languageMediaFormData.forEach((value: FormDataEntryValue, key: string) => {
                formData.append(key, value);
            });
        }

        formData.append("name", this.packageName?.value);

        formData.append("message", this.message?.value);

        if (this.selectedOption.value === "specific") {
            if (this.scheduleReleaseDate.value && this.scheduleReleaseTime?.value?.value) {
                formData.append("scheduledReleaseTimestamp", moment.utc(moment(this.scheduleReleaseDate.value).format("YYYY-MM-DD").concat(" ", this.scheduleReleaseTime.value.value)).format());
            }
        }

        if (this.selectedOption.value === "manual") {
            formData.append("manualDistribution", "true");
        }

        const initialState: UploadProgress = { state: "PENDING", progress: 0 };

        const calculateState = (uploadProgress: UploadProgress, event: HttpEvent<unknown>): UploadProgress => {
            if (event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress) {
                const progress: number = event.total ? Math.round((100 * event.loaded) / event.total) : uploadProgress.progress;
                this.uploadProgress.next(progress);

                if (progress === 100) {
                    this.uploadingFiles.next(false);
                    this.processingFiles.next(true);
                }

                return { progress, state: "IN_PROGRESS" };
            }

            if (event.type === HttpEventType.Response) {
                this.indicativeService.sendEvent("app_submission_form_success");

                this.processingFiles.next(false);
                this.filesUploadService.files.next(null);
                this.submitting.next(false);
                this.anyDoneHasBeenClicked.next(false);
                this.notificationsService.loadLatestNotificationsAndResetInterval();

                if (!this.userService.loggedAccount.value.activeSubscription) {
                    const dialogRef = this.dialog.open(PaymentNeededComponent, {
                        panelClass: "no-padding-dialog",
                        width: "90dvw",
                        maxWidth: "500px",
                        data: "submission",
                    });

                    dialogRef.afterClosed().subscribe({
                        next: (res) => {
                            this.appsListService.getAllList(true);
                            this.appsListService.changeTab(this.appsListService.appsStates[0]);
                            this.resetFormAndCardsDisplayAndPackageName();
                            if (res === "later") {
                                this.router.navigate(["/myApps"]);
                            }
                            if (res === "subscribe") {
                                this.router.navigate(["/payments"]);
                            }
                        },
                    });
                } else {
                    const dialogRef = this.dialog.open(ItsOnOurSideComponentModal, {
                        panelClass: "no-padding-dialog",
                        width: "90dvw",
                        maxWidth: "500px",
                    });
                    dialogRef.afterClosed().subscribe({
                        next: (res) => {
                            this.appsListService.getAllList(true);
                            this.appsListService.changeTab(this.appsListService.appsStates[0]);
                            this.resetFormAndCardsDisplayAndPackageName();
                            this.router.navigate(["/myApps"]);
                        },
                    });
                }

                return { progress: 100, state: "DONE" };
            }
            return uploadProgress;
        };

        return this.http
            .post(url, formData, { reportProgress: true, observe: "events" })
            .pipe(scan(calculateState, initialState))
            .pipe(
                catchError((error) => {
                    const errorMessage: string = (() => {
                        if (
                            (typeof error.status === "string" && error.status.includes("504")) ||
                            (error.error && error.error.data && error.error.data.body && typeof error.error.data.body === "string" && error.error.data.body.includes("504")) ||
                            (error.error && error.error.description && typeof error.error.description === "string" && error.error.description.includes("504")) ||
                            error.status == 504
                        ) {
                            return "504 Gateway Time-out! Your submission is being processed. You will receive an email confirmation once the process is complete. Thank you for your patience.";
                        }
                        if (error.error && error.error.data && error.error.data.body && typeof error.error.data.body === "string") {
                            return error.error.data.body;
                        }
                        if (error.error && error.error.description) {
                            return error.error.description;
                        }
                        return null;
                    })();

                    if (!errorMessage && this.submitRetries.value < 1) {
                        this.submitRetries.next(this.submitRetries.value + 1);
                        this.submitApp();
                        return null;
                    }
                    this.indicativeService.sendEvent("app_submission_form_fail");

                    const formattedErrorMessage: string = errorMessage ? errorMessage : this.translate.instant("upload.form.errorSubmit");

                    this.accountsService.setFeedback(`App submission error "${formattedErrorMessage}" for app ${this.packageName.value}`).subscribe();
                    (<any>window).Intercom("show");
                    this.submitErrors.next(formattedErrorMessage);
                    this.submitting.next(false);
                    throw { message: formattedErrorMessage };
                })
            )
            .subscribe({
                next: () => {},
                error: () => {},
            });
    }

    resetFormAndCardsDisplayAndPackageName() {
        this.packageName.next(null);
        this.resetFormCardsDisplayStatus();
        this.resetForm();
    }

    resetFormCardsDisplayStatus() {
        let formCards: FormCard[] = this.formCards.value;

        formCards.forEach((card: FormCard) => {
            if (card.name !== "ownership-via-token" && card.name !== "metadata-view") {
                card.done = false;
            }
            card.closed = false;
        });

        this.formCards.next(formCards);
    }

    resetScheduleRelease() {
        this.scheduleReleaseDate.next(null);
        this.scheduleReleaseTime.next(null);
    }

    resetForm() {
        this.billingService.reset(this.packageName.value, this.operatingSystem.value);
        this.filesUploadService.reset();
        this.distributionCountriesSubmissionService.reset();
        this.appInformationService.reset();
        this.languageMediaService.reset();
        this.distributionPolicyMorService.reset();
        this.resetScheduleRelease();
        this.submitErrors.next(null);
        this.submitting.next(false);
        this.uploadProgress.next(0);
        this.processingFiles.next(false);
        this.uploadingFiles.next(false);
        this.submitRetries.next(0);
    }

    openScheduleRelease() {
        const dialogRef = this.dialog.open(ReleaseScheduleComponent, {
            panelClass: "dialog-with-dropdown",
            width: "90dvw",
            maxWidth: "550px",
            autoFocus: false,
            data: {
                date: this.scheduleReleaseDate.value ? this.scheduleReleaseDate.value : null,
                time: this.scheduleReleaseTime.value ? this.scheduleReleaseTime.value : null,
                selectedOption: this.selectedOption.value,
                showManual: true,
            },
        });

        dialogRef.afterClosed().subscribe({
            next: (res) => {
                if (!res) {
                    return;
                }
                this.selectedOption.next(res.selectedOption);
                if (res.selectedOption === "specific") {
                    this.scheduleReleaseDate.next(res?.date);
                    this.scheduleReleaseTime.next(res?.time);

                    return;
                }

                this.scheduleReleaseDate.next(null);
                this.scheduleReleaseTime.next(null);
            },
        });
    }
}
