<div class="modal-container">
    <img class="close-icon" (click)="closeDialog()" src="/assets/images/closeX.svg" />
    <div class="notification">
        <div class="first-row">
            <div class="avatar">
                <img
                    #img
                    class="image white-background"
                    [src]="notification | notificationIcon | async"
                    (error)="img.src = !!notification.applicationName ? '/assets/images/appicon-fallback.svg' : '/assets/images/catappult-small-logo.png'"
                />
                <img *ngIf="notification.code === 'NEW_MARKET_APPLICATION_WITH_BILLING_VERSION_FOUND'" class="badge" src="/assets/images/update-badge.svg" />
            </div>

            <div class="text-container">
                <p *ngIf="notification.code === 'CUSTOM'" [class.force-white]="!!dashboardMobile" class="xs bold very-low-line-height title">
                    {{ notification.shortDescription }}
                </p>
                <p
                    *ngIf="notification.code !== 'CUSTOM'"
                    [innerHTML]="'notifications.' + notification.code + '.title' | translate : { notification: notification }"
                    [class.force-white]="!!dashboardMobile"
                    class="xs bold very-low-line-height title"
                ></p>
                <div class="time-and-chip">
                    <div class="bullet"><span *ngIf="!notification.read">•</span></div>
                    <div class="xxxs darkGray">{{ notification.time }}</div>
                    <div *ngIf="!!notification.type" class="chip" [class]="notification.type">
                        <p class="xxxs" translate>{{ "notificationCopies." + notification.type }}</p>
                    </div>
                </div>
            </div>
        </div>
        <p
            *ngIf="notification.hasLongDescription && notification.longDescription && notification.code === 'CUSTOM'"
            class="xxs long-description margin-left-but-not-on-mobile"
            [innerHTML]="notification.longDescription"
        ></p>
        <p
            *ngIf="notification.hasLongDescription && !notification.longDescription && notification.code !== 'CUSTOM'"
            [innerHTML]="'notifications.' + notification.code + '.long' | translate : { notification: notification }"
            class="xxs long-description margin-left-but-not-on-mobile"
        ></p>
    </div>

    <app-button
        class="know-more-button margin-left-but-not-on-mobile"
        *ngIf="notification.url"
        [veryVerySmall]="true"
        [blueOnTransparent]="true"
        [label]="'notificationCopies.knowMore' | translate"
        (onClick)="goToURL(notification.url)"
    ></app-button>
</div>
