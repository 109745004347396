import { Injectable } from "@angular/core";
import { APIRequestsService } from "@app/core/services/api.requests.service";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class ReachFurtherCardService {
    pendingSubmissions: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    loadingPendingSubmissions: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor(private requestsService: APIRequestsService) {}

    checkPendingSubmissions() {
        this.loadingPendingSubmissions.next(true);
        this.requestsService.get(`/api/applications/importedPendingSubmission`).subscribe({
            next: (res: any) => {
                const filteredRes = res.filter((submission: any) => {
                    return submission.blacklisted != true;
                });
                this.pendingSubmissions.next(filteredRes);
                this.loadingPendingSubmissions.next(false);
            },
        });
    }
}
