import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";

@Component({
    selector: "app-text-input",
    templateUrl: "./text-input.component.html",
    styleUrls: ["./text-input.component.scss"],
})
export class TextInput implements OnInit {
    @Input() label: string = "";
    @Input() mandatory: boolean = false;
    @Input() placeholder: string = "";

    @Input() pattern: string = "";
    @Input() maxlength: string = "";
    @Input() value: any = "";
    @Input() unit: string = "";
    @Input() showClear: boolean = true;

    @Input() showSpecificError: boolean = false;
    @Input() specificError: string = "";
    @Input() showError: boolean = false;
    @Input() error: string = "";
    @Input() disabled = false;
    @Input() readOnly = false;
    @Input() normal = true;
    @Input() ellipsis = false;
    @Input() noBorderRadiusRight = false;
    @Input() noBorderRadiusLeft = false;
    @Input() attachOnLeft = false;
    @Input() attachToButtonOnRight = false;
    @Input() otpInput = false;
    @Input() name = "";
    @Input() tooltip = "";
    @Input() type = "text";
    @Input() class = null;
    @Input() iconUrl = null;
    @Input() big = false;
    @Input() veryBig = false;
    @Input() smallPlaceHolder = false;
    @Input() rounded: boolean = false;
    @Input() veryRounded: boolean = false;
    @Input() filterImage: boolean = false;
    @Input() onlyEmitClear: boolean = false;
    @Input() lightPlaceholder: boolean = false;
    @Input() noBorder: boolean = false;

    @Output()
    inputKeyUpEnter = new EventEmitter();

    @Output()
    clearClicked = new EventEmitter();

    @Output()
    onChange = new EventEmitter();

    @Output()
    onBlur = new EventEmitter();

    @ViewChild("textInput") inputElement: ElementRef<HTMLInputElement>;

    constructor() {}

    ngOnInit() {}

    onEnter(event) {
        this.inputKeyUpEnter.emit(this.value);
    }

    changed(event: any) {
        this.value = event;
        this.onChange.emit(this.value);
    }

    clear() {
        this.value = null;
        this.changed(null);
    }

    clearEmit() {
        this.clearClicked.emit(true);
    }
}
